import { createAsyncThunk } from "@reduxjs/toolkit";
import dealvpsService from "../../services/dealvpsService";

export const featchDomaindropdwon =createAsyncThunk(
 "DomainDetail/fetchData",
    async () => {
      try {
        const userName = localStorage.getItem("userName");
        const res = await dealvpsService.get(
          // `/api/v1/general/query/GET_ALL_INDIVIDUAL_NAMES_FROM_INDIVIDUAL_MANAGEMENT/${userName}`
           `/api/v1/general/query/GET_ALL_DOMAINS`
        );
        
        return res.data;
      } catch (error) {
        throw error;
      }
    }
  );




