import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { environment } from "../../environment/environment";


export const fetchRoles = createAsyncThunk("roles/fetchData", async () => {
  try {
    const response = await fetch(`${environment.BASE_URL}/api/v1/content/get-master-data/Role Map`);
    // const response = await fetch(`${environment.BASE_URL}/api/v1/content/get-master-data/Role Map`);
    const data = await response.json();
    console.log("Role",data);
    return data;
  } catch (error) {
    throw error;
  }
});


const RoleMappingSlice = createSlice({
  name: "roles",
  initialState: {
    status: "idle",
    error: null,
    data: [],
    userRoles: [],
  },
  reducers: {
    addNewRole: (state, action) => {
      state.userRoles = [...state?.userRoles, action.payload];
      console.log("new role added ", state.userRoles);

    },
    setUserRoles: (state, action) => {

      const filterr = action.payload.filter(obj => ['R101', 'R102', 'R103', 'R104'].includes(obj.roleName));
      const filter = filterr.map(obj => {

        switch (obj.roleName) {
          case 'R101':
            return {
              ...obj,
              roleId: 'Project Manager',

            }
            break;
          case 'R102':

            return {
              ...obj,
              roleId: 'Client',

            }
            break;
          case 'R103':

            return {
              ...obj,
              roleId: 'Service Provider',

            }
            break; // Add your conditions for other roles similarly
          case 'R104':

            return {
              ...obj,
              roleId: 'Project Assistant',

            }
            break;
          case 'R5':

            return {
              ...obj,
              roleId: 'Government Agency',

            }
            break;
          case 'R6':

            return {
              ...obj,
              roleId: 'Support Services'

            }
            break;
          default:
            break;
        }
        return true;

      }).filter(obj => obj !== null);
      state.userRoles = filter;
      console.log("user roles ", state.userRoles);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});



export const { setLanguageContent, setUserRoles, addNewRole } = RoleMappingSlice.actions;
export default RoleMappingSlice.reducer;
