import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isMenuOpen: true
};

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        togglemenu: (state, action) => {
            state.isMenuOpen = action.payload;
        }
    }
});

export const { togglemenu } = menuSlice.actions;
export default menuSlice.reducer;