import { createSlice } from "@reduxjs/toolkit";
import { featchJournalData } from "../../api/Journalmanagementservice/featchJournalData";

const initialState = {
  data: [],
  status: "idle", 
  error: null,
};

const JournalSlice = createSlice({
  name: "journalDetail",
  initialState,
  reducers: {
    clearJournal: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(featchJournalData.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(featchJournalData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(featchJournalData.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { clearJournal } = JournalSlice.actions;
export default JournalSlice.reducer;