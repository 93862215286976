import { createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../../environment/environment";
import dealvpsService from "../../services/dealvpsService";

export const fetchUserLanguages = createAsyncThunk(
  "DetailProfile/UserLanguages",
  async () => {
    try {
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");
      const response = await dealvpsService.get(
        `${environment.BASE_URL}/api/v1/skills/get-all-user-data-by-userId/User Languages/${userId}?authToken=${token}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
