import { createSlice } from '@reduxjs/toolkit';

const languageSlice = createSlice({
  name: 'language',
  initialState: 'EN-US', // Set the default language here
  reducers: {
    setLanguage: (state, action) => {
      return action.payload;
      },

    },
  
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
