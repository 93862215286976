import { createAsyncThunk } from "@reduxjs/toolkit";
import dealvpsService from "../../services/dealvpsService";

export const OrgNameAutoComplete = createAsyncThunk(
  "AutoComplete/fetchData",
  async () => {
    try {
      const userName = localStorage.getItem("userName");
      const res = await dealvpsService.get(
        `/api/v1/general/query/GET_ALL_ORGANIZATION_NAME_FROM_ORGANIZATION_MANAGEMENT`
      );
      
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);
