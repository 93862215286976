import { createSlice } from "@reduxjs/toolkit";
import { featchProjectData } from "../../api/fetchAllData/featchProjectData";


const initialState = {
  data: [],
  status: "idle", 
  error: null,
};

const ProjectDataSlice = createSlice({
  name: "ProjectDetail",
  initialState,
  reducers: {
    clearProject: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(featchProjectData.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(featchProjectData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(featchProjectData.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { clearProject } = ProjectDataSlice.actions;
export default ProjectDataSlice.reducer;
