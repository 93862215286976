import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { environment } from "../../environment/environment";



export const fetchLanguages = createAsyncThunk("content/fetchLanguages", async () => {
    try {
      const response = await fetch(`${environment.BASE_URL}/api/v1/content/getLanguages`);
      const data = await response.json();
      console.log("datas ", data);
      return data;
    } catch (error) {
      throw error;
    }
  });




const listOfLangSlice = createSlice({
    name: "langList",
    initialState: {
      AllLanguage: [],
      status: "idle",
      error: null,
    },
    reducers: {
      setLanguageContent: (state, action) => {
        state.language = action.payload;
      },
      getLanguageContent: (state) => { 
        return state.en;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchLanguages.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchLanguages.fulfilled, (state, action) => {
          state.status = "succeeded";
          
          
          state.AllLanguage = action.payload;
        })
        .addCase(fetchLanguages.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        });
    },
  });
  
  
  
  export const { setLanguageContent } = listOfLangSlice.actions;
  export default listOfLangSlice.reducer;
  
