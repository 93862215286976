import { createSlice } from "@reduxjs/toolkit";
import { fetchCountryRegional } from "../../api/content/fetchRegionalCountries"




const CountryRegionalSlice = createSlice({
    name: 'regionalData',
    initialState: {
        listOfCountries: [],
        status: "", error: "",
        selectedCountry: {
            country: "United States",
            region: "USA", continent: "America",
            dateFormat: "mm/dd/yyyy",
            currency: "$",
            currencySeparator: "2,2",
            timeZone: "",
            homeLanguage: "EN-US",
            homeCountry: "us",
            language: "EN",
            length: "meter",
            weight: "kg",
            borderColor: "red",
            countryCode: "US",
        },

    }, // Set the default language here
    reducers: {
        setRegCountry: (state, action) => {

            state.selectedCountry = action.payload;
        },
        getList: (state) => {
            return state.listOfCountries;
        }


    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCountryRegional.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCountryRegional.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.listOfCountries = action.payload;
            })
            .addCase(fetchCountryRegional.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },

});
export const { setRegCountry } = CountryRegionalSlice.actions;
export default CountryRegionalSlice.reducer;
