import { createSlice } from "@reduxjs/toolkit";
import { OrgNameAutoComplete } from "../../api/organizationmasterservice/OrgNameAutoComplete";



const initialState = {
  data: [],
  status: "idle", 
  error: null,
};

const OrgNameAutoCompleteSlice = createSlice({
  name: "OrgNameAutoDetail",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(OrgNameAutoComplete.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(OrgNameAutoComplete.fulfilled, (state, action) => {
        console.log("GetOrgname",action.payload);
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(OrgNameAutoComplete.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export default OrgNameAutoCompleteSlice.reducer;