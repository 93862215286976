import { createSlice } from "@reduxjs/toolkit";



const initialState = {
  data: null,
 
};

const EditDataslice = createSlice({
  name: "EditData",
  initialState,
  reducers: {
    setEditData: (state, action) => {
      state.data = action.payload;
     
    },
   
    LogoutEditData: () => initialState
  },

});

export const { setEditData, LogoutEditData } = EditDataslice.actions;
export default EditDataslice.reducer;
