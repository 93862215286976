import { Component } from "react";

class ErrorBoundary extends Component {
    constructor (props) {
        super(props);
        this.state = { hasError: false };
    }

    static getStateError(err) {
        return { hasError: true }
    }

    /* CATCH ERROR */
    componentDidCatch(error, info) {
        console.error("ERROR BOUNDARY CATCH AN ERROR: ", info, error);
    }

    /* RENDER AND RETURN */
    render() {
        /* INCASE OF ERROR */
        if (this.state?.hasError) {
            return <p>Something Went Wrong...</p>
        }

        /* INCASE OF NO ERROR */
        return this.props.children;
    }
}
 
export default ErrorBoundary;