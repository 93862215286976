import { createSlice } from "@reduxjs/toolkit";
import { featchOrganizationData } from "../../api/organizationmasterservice/featchOrganizationData";

const initialState = {
  data: [],
  status: "idle", 
  error: null,
};

const OrganizationSlice = createSlice({
  name: "OrganizationDetail",
  initialState,
  reducers: {
    clearOrg: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(featchOrganizationData.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(featchOrganizationData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(featchOrganizationData.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { clearOrg } = OrganizationSlice.actions;
export default OrganizationSlice.reducer;