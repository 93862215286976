// store.js
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../reducer/rootReducer"
import { fetchData } from "../reducer/localization/contentSlice";

import { fetchRoles } from "../reducer/roles/RoleMappingSlice";
 import { fetchLanguages } from "../reducer/localization/listOfLangSlice";
import { fetchCountryRegional } from "../api/content/fetchRegionalCountries"
import { featchProjectData } from "../api/fetchAllData/featchProjectData";
import { featchOrganizationData } from "../api/organizationmasterservice/featchOrganizationData";
import { featchIndividualData } from "../api/Individualmasterservice/featchIndividualData";
import { featchJournalData } from "../api/Journalmanagementservice/featchJournalData";



const store = configureStore({
  reducer: rootReducer,

});


store.dispatch(fetchData());
store.dispatch(fetchLanguages());
store.dispatch(fetchRoles());
store.dispatch(fetchCountryRegional());
// store.dispatch(featchProjectData());
// store.dispatch(featchOrganizationData());
// store.dispatch(featchIndividualData());
// store.dispatch(featchJournalData());





export default store;
