import { createSlice } from "@reduxjs/toolkit";
import { fetchUserLanguages } from "../../api/fetchAllData/fetchUserLanguages";


const initialState = {
  data: [
    {
      mtype: "Languages",
      fromDate: "",
      toDate: "",
      duration: "",
      briefDescriptions: "",
      location: "",
      showHide: "",
      validation: "",
      blockChain: "",
      mlanguage: "",
      certificationName: "",
      organization: "",
      userId: "",
      title: ""
    }
  ],
  status: "idle", // possible values: "idle", "loading", "success", "error"
  error: null,
};

const LanguageKnownSlice = createSlice({
  name: "languageKnownHistory",
  initialState,
  reducers: {
    setKnownLanguages: (state, action) => {
      state.data = action.payload;
      state.status = "success";
      state.error = null;
    },
    emptyKnownLanguages: (state) => {
      state.data = [];
      state.status = "idle";
      state.error = null;
    },
    addNewKnownLanguages: (state, action) => {
      state.data = [action.payload, ...state.data];
      state.status = "success";
      state.error = null;
    },
    KnownLanguagesDelete: (state, action) => {
      state.data = state.data.filter((skill) => skill.id !== action.payload);
    },
    logoutKnownLanguages: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserLanguages.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchUserLanguages.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(fetchUserLanguages.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { setKnownLanguages, emptyKnownLanguages,addNewKnownLanguages,KnownLanguagesDelete,logoutKnownLanguages } = LanguageKnownSlice.actions;
export default LanguageKnownSlice.reducer;
