import { createSlice } from "@reduxjs/toolkit";
import { featchIndividualData } from "../../api/Individualmasterservice/featchIndividualData";


const initialState = {
  data: [],
  status: "idle", 
  error: null,
};

const IndividualSlice = createSlice({
  name: "IndividualDetail",
  initialState,
  reducers: {
    clearIndividual: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(featchIndividualData.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(featchIndividualData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(featchIndividualData.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { clearIndividual } = IndividualSlice.actions;
export default IndividualSlice.reducer;