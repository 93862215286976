import { createAsyncThunk } from "@reduxjs/toolkit";
import dealvpsService from "../../services/dealvpsService";

export const fetchCountryRegional = createAsyncThunk(
    "countries/RegionalSettings",
    async () => {
        console.log("selected Lang: ");

        try {
            const response = await dealvpsService.get(
                `/api/v1/content/RegionalData/Regional Settings?searchFieldName=mlanguage&searchValue=EN-US`
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);
