import './App.css';
import { lazy, Suspense, useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { setRegCountry } from './reducer/localization/CountryRegionalSlice.js';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary.jsx';
import Loader from './components/loader/Loader.jsx';




/* LAZY LOADING COMPONENTS */
const SigninPage = lazy(() =>
  import("./pages/Auth/Signin.jsx")

);

const SignupPage = lazy(() =>
  import("./pages/Auth/Signup.jsx")

);

const ForgetPage = lazy(() =>
  import("./pages/Auth/useForgotPassword.jsx")

);

const ForgotNoAccessPage = lazy(() =>
  import("./pages/Auth/ForgotNoAccess.jsx")

);


const ForgotPasswordWithAltEmailPage = lazy(() =>
  import("./pages/Auth/ForgotPasswordWithAltEmail.jsx")

);

const PageReset = lazy(() =>
  import("./pages/Auth/Reset.jsx")

);



const DashbordmainPage = lazy(() =>
  import("./pages/Dashbords/Dashbordmain.jsx")

);

const Sidemanupage = lazy(() =>
  import("./pages/Default/sidenav.jsx")

);

const Topbarpage = lazy(() =>
  import("./pages/Default/Topbar.jsx")

);

const Footerpage = lazy(() =>
  import("./pages/Default/Footer.jsx")

);

const Pagetitlepage = lazy(() =>
  import("./pages/Default/Pagetitle.jsx")

);

const PageLogout = lazy(() =>
  import("./pages/Auth/AuthLogout.jsx")

);

const PageProjectMasterList = lazy(() =>
  import("./pages/ProjectMaster/Projectmaster.jsx")

);

const PageClientMasterList = lazy(() =>
  import("./pages/ClientMaster/Clientmasterlist.jsx")
);

const PageServiceproviderlist = lazy(() =>
  import("./pages/ManageService/Serviceproviderlist.jsx")
);

const PageOrganizationList = lazy(() =>
  import("./pages/Masters/Organization/OrganizationList.jsx")
);
const PageIndividualList = lazy(() =>
  import("./pages/Masters/IndividualMaster/IndividualList.jsx")
);
const PageJournalList = lazy(() =>
  import("./pages/JournalManagement/JournalManagementList.jsx")
);
const Dataroom = lazy(() =>
  import("./pages/Dataroom/Dataroom.jsx")
);






function App() {
  const dispatch = useDispatch();
  const regionalData = useSelector(state => state.regionalData.listOfCountries);
  const selectedCountry = useSelector(state => state.regionalData.selectedCountry);

  useEffect(() => {
    console.log("regionalData22 ", regionalData);
    if (Array.isArray(regionalData)) {
    regionalData?.map((country) => {
      if (country?.countryCode === localStorage.getItem("countryCode")) {
        dispatch(setRegCountry(country));
        return;
      }
    })
  }
  }, [localStorage.getItem("countryCode"), localStorage.getItem("TimeZone")])
  useEffect(() => {
    console.log("regionalData211 ", regionalData);
    if (Array.isArray(regionalData)) {
    regionalData?.map((country) => {
      if (country?.countryCode === localStorage.getItem("countryCode")) {
        dispatch(setRegCountry(country));
        return;
      }
    })
  }
  }, [])

  useEffect(() => {
    console.log("selectedCountry ", selectedCountry);

  }, [selectedCountry]);

  const [count, setCount] = useState(0)

  return (
   <ErrorBoundary>
    
   <Suspense fallback={<Loader />}>
   <Routes>
   
      <Route index element={<SigninPage/>}/>

 
      <Route path='/Default' element={<Topbarpage/>}/>
      <Route path='/Default' element={<Sidemanupage/>}/>
      <Route path='/Default' element={<Footerpage/>}/>
      <Route path='/Default' element={<Pagetitlepage/>}/>
      <Route path='/login' element={<SigninPage/>}/>
      <Route path='/signup' element={<SignupPage/>}/>
      <Route path='/useforgotpassword' element={<ForgetPage/>}/>
      <Route path='/reset/:id' element={<PageReset/>}/>
      <Route path='/forgotnoaccess' element={<ForgotNoAccessPage/>}/>
      <Route path='/forgotpasswordwithaltemail' element={<ForgotPasswordWithAltEmailPage/>}/>
      <Route path='/authlogout' element={<PageLogout/>}/>
      <Route path='/dashbordmain' element={<DashbordmainPage/>}/>
      <Route path='/projectmaster' element={<PageProjectMasterList/>}/>
      <Route path='/organizationlist' element={<PageOrganizationList/>}/>
      <Route path='/Clientmasterlist' element={<PageClientMasterList/>}/>
      <Route path='/Serviceproviderlist' element={<PageServiceproviderlist/>}/>
      <Route path='/IndividualList' element={<PageIndividualList/>}/>
      <Route path='/JournalList' element={<PageJournalList/>}/>
      <Route path='/dataroom' element={<Dataroom/>}/>


    </Routes>
   </Suspense>
   </ErrorBoundary>
  )
}


export default App
