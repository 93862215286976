import { createSlice } from "@reduxjs/toolkit";
import { featchDomaindropdwon } from "../../api/fetchAllData/featchDomaindropdwon";



const initialState = {
  data: [],
  status: "idle", 
  error: null,
};

const DomainDropdownSlice =  createSlice({
    name: "DomainDetail",
    initialState,
  
    extraReducers: (builder) => {
      builder
        .addCase(featchDomaindropdwon.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(featchDomaindropdwon.fulfilled, (state, action) => {
        
          state.data = action.payload;
          state.status = "success";
          state.error = null;
        })
        .addCase(featchDomaindropdwon.rejected, (state, action) => {
          state.status = "error";
          state.error = action.error.message;
        });
    },
  });


export default DomainDropdownSlice.reducer